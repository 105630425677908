.accordion > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.accordion > input[type="checkbox"]:checked ~ .content {
  height: auto;
  overflow: visible;
  border-bottom: 1px solid #818996;
  padding: 0 12px 12px;
  border-top: 0;
}

.accordion > input[type="checkbox"]:checked ~ label {
  border-bottom: none;
}

.accordion > input[type="checkbox"]:checked ~ label .toggle-up {
  display: inline-block !important;
}

.accordion > input[type="checkbox"]:checked ~ label .toggle-down {
  display: none !important;
}

.accordion > input[type="checkbox"]:checked ~ label + .content {
  opacity: 1;
  transition: 0.3s;
  height: auto;
}

.accordion > label {
  display: block;
  cursor: pointer;
  background: #fff;
  border-bottom: 1px solid #818996;
  position: relative;
}

.accordion > label > svg {
  margin-right: 12px;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  vertical-align: middle;
  position: absolute;
  right: -8px;
  top: 14px;
}

.accordion > label > svg.toggle-down {
  display: inline-block;
}

.accordion > label > svg.toggle-up {
  display: none;
}

.accordion .content {
  opacity: 0;
  transition: 0.3s;
  height: 0;
}

.accordion .content input.checkbox-custom,
.modal__content input.checkbox-custom {
  margin-bottom: 4px !important;
}
