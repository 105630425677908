@media screen and (max-width: 1024px) {
  .order-list--orderId {
    overflow-x: hidden;
    max-width: 160px;
    text-overflow: ellipsis;
  }
  .order-list--date {
    max-width: 100px;
  }
  .order-list--expiryTime {
    max-width: 112px;
  }
}
