.shipping-dropdown > div {
  width: 100% !important;
  position: relative;
}

.shipping-dropdown > div > div > div {
  width: 100%;
}

.courier-dropdown > div {
  width: 100%;
}
.courier-dropdown > div > div > div {
  width: 100%;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #e2e4e8 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 99px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e2e4e8;
  border-radius: 99px;
  border: 2px solid #ffffff;
}

.shipping-type-dropdown-v2 > span {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
