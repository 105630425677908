.banner-image-gallery {
  width: 100%;
}

.gallery-container {
  margin-top: 50px;
}

.image-gallery-svg {
  width: 20px !important;
  height: 30px !important;
}

.image-gallery-swipe {
  border-radius: 8px;
  overflow: hidden;
}

.image-gallery-image {
  min-height: 350px;
  object-fit: cover !important;
}

.banner-image-gallery .image-gallery-slide-wrapper {
  display: inline-flex;
}

.banner-image-gallery .image-gallery-slide-wrapper > button {
  top: 45%;
  display: none;
}

.banner-image-gallery .image-gallery-slide-wrapper:hover > button {
  display: flex;
}

.banner-image-gallery .image-gallery-bullets {
  width: 80%;
  margin: 0 0 0 16px;
}

.banner-image-gallery .image-gallery-bullets .image-gallery-bullets-container {
  display: flex;
}

.banner-image-gallery .image-gallery-bullet {
  background-color: #fff;
  opacity: 0.7;
  width: 8px;
  height: 8px;
  padding: 3px;
  margin: 0 2px;
}

.banner-image-gallery .image-gallery-bullet.active {
  width: 48px !important;
  border-radius: 5px;
  opacity: 1;
  margin: 0 8px;
}
