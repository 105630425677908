.modal__inner {
  border-radius: 16px !important;
}

/* Overwrite Legion's Chip */

.chip {
  background-color: #f5faff !important;
  border-radius: 4px !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #e2e4e8 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 99px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e2e4e8;
  border-radius: 99px;
  border: 0px solid #ffffff;
}
